:root {
	--active-color: #5691ff;
}

::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #191c25;
}

::-webkit-scrollbar {
	width: 5px;
	background-color: #191c25;
}

::-webkit-scrollbar-thumb {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #555;
}

body[class='dark-mode'] {
	--text-color: #fff;
	--background-color: #333;
	--background-light-color: #3e3d3d;
	--muted-color: #787373;
	--video-active-color: #fff;
}

body[class='light-mode'] {
	--video-active-color: #fff;
	--text-color: #333;
	--background-color: #f1f1f1;
	--background-light-color: #fcfcfc;
	--muted-color: #999595;
}

.flex {
	display: flex;
}

a {
	color: var(--text-color);
	text-decoration: none;
}

body {
	background: var(--background-light-color);
	color: var(--text-color);
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

textarea {
	width: 79%;
	font-size: 12px;
	border-radius: 8px;
	line-height: 20px;
	margin-left: 37px;
	min-height: 160px;
	background: var(--background-light-color);
	color: var(--text-color);
	border: 0px;
	padding: 0.5rem;
	-webkit-box-shadow: none;
	outline: -webkit-focus-ring-color auto 0px;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

li {
	list-style-type: none;
}

.alert-error {
	text-align: center;
	background: #e75146;
	color: #fff;
	padding: 0.4rem;
	font-size: 12px;
	margin-bottom: 11px;
}

.center {
	justify-content: center;
	align-items: center;
}

li .delete {
	visibility: hidden;
	float: right;
}

li:hover .delete {
	visibility: visible;
	float: right;
	position: relative;
	top: -39px;
	right: 12px;
	color: var(--text-color);
}

.right {
	display: flex;
	justify-content: flex-end;
}

.d-flex {
	display: flex;
	flex-wrap: wrap;
}

.flex-grow-1 {
	flex-grow: 1;
}

.pl-5 {
	padding-left: 5px;
}

.f-24 {
	font-size: 24px;
}

.w-100 {
	width: 100%;
}

.text-muted {
	color: var(--muted-color);
	padding: 1rem;
}
.c-icon {
	position: relative;
	top: 7px;
}

.playlist-count {
	float: right;
	padding-right: 20px;
}

.round-button-inverse {
	border-radius: 100px;
	text-align: center;
	align-items: center;
	padding: 9px;
	cursor: pointer;
	font-size: 14px;
}

.round-button-inverse .MuiSvgIcon-root-1 {
	position: relative;
	top: 4px;
	left: 0;
}

.round-button-inverse:hover {
	background: var(--background-color);
}

.round-button {
	border-radius: 100px;
	text-align: center;
	align-items: center;
	padding: 9px;
	display: flex;
	margin-right: 20px;
	cursor: pointer;
	margin-top: 7px;
}
.round-button:hover {
	background: var(--background-light-color);
}

.button-primary {
	cursor: pointer;
	background: #5691ff;
	color: #fff;
	border: #5691ff 1px solid;
	border-radius: 4px;
	padding: 0.5rem;
}
.playlist-form {
	margin-top: 15px;
	margin-bottom: 50px;
}

.playlist-form button {
	float: right;
}
.playlist-form .button-primary {
	margin-right: 17px;
	margin-top: 8px;
}

.sidebar {
	background: var(--background-color);
	flex: 1;
	height: 100vh;
	overflow-y: scroll;
	overflow-x: hidden;
	color: var(--text-color);
}

.sidebar ul .MuiSvgIcon-root-1 {
	margin-top: 5px;
	padding-right: 10px;
}

.sidebar h4 {
	font-size: 14px;
	padding-left: 40px;
}

.sidebar li {
	line-height: 2rem;
	border-radius: 8px;
	cursor: pointer;
	font-size: 12px;
	margin-top: 5px;
	margin-right: 15px;
}

.nav-video .MuiSvgIcon-root {
	position: relative;
	top: 5px;
	padding-right: 15px;
	padding-left: 15px;
}

.active {
	background: var(--active-color);
}

.active .nav-video {
	color: var(--video-active-color);
}

.sidebar li .nav-video {
	padding: 0.5rem;
}

.sidebar li:hover {
	background: var(--background-light-color);
}
.sidebar li:hover a {
	color: var(--text-color);
}

.video-player {
	display: flex;
	justify-content: center;
	align-content: center;
	margin: 80px;
	margin: 0 auto;
}

.video-player-section {
	flex-grow: 1;
	flex: 4;
	display: flex;
	flex-direction: column;
}

.video-player-section .grow {
	flex-grow: 1;
}

.toolbar {
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 1rem;
	max-width: 100%;
}

.toolbar a {
	cursor: pointer;
}

.toolbar .round-button-inverse .MuiSvgIcon-root {
	position: relative;
	top: 5px;
}

.label-name {
	margin: 1rem 0;
}

.label-name span {
	color: #737373;
}

@media screen and (max-width: 600px) {
	.sidebar {
		width: 100%;
		flex: 2 1 500px;
	}
	.toolbar {
		position: unset;
	}
	.video-player {
		display: block;
		margin: 0px;
		margin-bottom: 30px;
	}
}
